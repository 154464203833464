/** globals */
window.canSubmit = false;
let currentLanguage = $('html').attr('lang');

/** scripts */
function jsonSend(form, data, jsonUrl) {
    // TODO : enable bottom function
    // form.find('button').prop('disabled', true);
    data = loadSIFieldData(form, data);
    //console.log(data);

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (xhttp.readyState === 4) {
            const response = JSON.parse(xhttp.responseText);
            if (response.status === 'update-accepted') {
                if (response.inspirationGuide) {
                    $('#ig-text').html('');
                    $('.inspiration-guide').html('<p style="color: #fff;font-size: 18px;">' + response.message + '</p>');
                } else if (response.message !== undefined) {
                    $('#contactForm').find('.user-message').remove();
                    $('#contactForm > .row').prepend('<div class="col-lg-8 contact-message--success"><i class="far fa-check-circle contact-icon--success"></i>Bedankt voor uw bericht!<br>We trachten hier zo spoedig mogelijk op te reageren.</div>');
                }
                toastr.success('The form has been saved', 'Saved!');
            } else {
                //response.status
                toastr.warning('An error occurred!\nPlease try again or contact the website admin.', 'Error!');
            }
        }
    };
    xhttp.open("POST", jsonUrl, true);
    xhttp.setRequestHeader("Content-type", 'application/json; charset=UTF-8');
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhttp.send(JSON.stringify(data));
}
/** Define which button activates which form */
const formSubmitScript = function() {
    $('form').on('submit', function() {
        return window.canSubmit;
    });
    /** General save form */
    $('.saveForm').click(function(){
        const id = $(this).data('target');
        const jsonUrl = $(id).attr('action');
        if ($(this).hasClass('recaptcha')) {
            saveForm(id, jsonUrl, true);
        } else {
            saveForm(id, jsonUrl, false);
        }
    });
    $.validate({
        //scrollToTopOnError : false,
        lang: currentLanguage,
        modules : 'html5, security'
    });
};



/** Ajax call to JSON pages */
const saveForm = function(formID, jsonUrl, useRecaptcha = false){
    let errors = [];
    let form = $(formID);
    let data = {
        csrf_token:form.find('.csrf_token').val(),
        cms_check:form.find('.cms_check').val(),
        baseUrl:window.location.href,
        reCAPTCHA:''
    };
    if( !form.isValid(currentLanguage) ) {
        /*$('html, body').animate({
            scrollTop: $("#new-member").offset().top
        }, 800);*/
        console.log('Note: incomplete form.');
    } else {
        // The form is valid
        if (useRecaptcha) {
            grecaptcha.execute(reCAPTCHA_key).then(function(token) {
                data.reCAPTCHA = token;
                jsonSend(form, data, jsonUrl);
            });
        } else {
            jsonSend(form, data, jsonUrl);
        }
    }
};

/** Get all data from within a container */
const loadSIFieldData = function (container, data) {
    /** JSON data for text, textarea, richtext */
    container.find('.json-text, .json-select').each(function () {
        data = setJsonValue(data, $(this).attr('name'), $(this).val());
    });
    /** JSON data for boolean */
    container.find('.json-boolean').each(function () {
        let checkedVal = false;
        if ($(this).is(':checked')) {
            checkedVal = true;
        }
        data = setJsonValue(data, $(this).attr('name'), checkedVal);
    });
    /** JSON data for Option */
    container.find('.json-option').each(function () {
        if ($(this).is(':checked')) {
            data = setJsonValue(data, $(this).attr('name'), $(this).val());
        }
    });
    /** JSON data for boolean */
    container.find('.json-number').each(function () {
        let val = Number($(this).val());
        data = setJsonValue(data, $(this).attr('name'), val);
    });
    // console.log(data);
    return data;
};
/** Set our json data as corrected json data */
let jsonCounter = 0;
function setJsonValue(data, name, value) {
    // Check for arrays in the name
    if (name === undefined) {
        console.log('input name not defined.');
        return false;
    }
    if (name.indexOf("[")) {
        let find = new RegExp(']', 'g');
        let m = name.replace(find, '').split('[');
        data = setIterateJsonValue(data, value, m);
    } else {
        // else we don't have to deal with arrays
        if (name === '') {
            name = jsonCounter;
            jsonCounter++;
        }
        data[name] = value;
    }
    return data;
}
function setIterateJsonValue(setter, value, object, now = 0) {
    if (object.length>now) {
        // First set empty value to number
        if (object[now] === undefined || object[now] === '') {
            object[now] = jsonCounter;
            jsonCounter++;
        }
        // Then
        if (setter[object[now]] === undefined) {
            setter[object[now]] = {};
        }
        setter[object[now]] = setIterateJsonValue(setter[object[now]], value, object, now+1)
    } else {
        setter = value;
    }
    return setter;
}
/** Check whether var is json */
function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
/** Toastr-popup settings */
const toastrOptions = function(){
    toastr.options = {
        "closeButton": false,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };
    /*
    // Display a warning toast, with no title
    toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!');
    // Display a success toast, with a title
    toastr.success('Have fun storming the castle!', 'Miracle Max Says');
    // Display an error toast, with a title
    toastr.error('I do not think that word means what you think it means.', 'Inconceivable!');
     */
    // TODO : work with single temp sessions to load Toastr messages
};

/** LOAD ALL FUNCTIONS */
$(document).ready(function(){
    formSubmitScript();
    toastrOptions();
});